import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ElementRef
} from '@angular/core'
import * as _ from 'lodash-es'
import { AppState } from '../../store/reducers'
import { select, Store } from '@ngrx/store'
import { FormBuilder, FormGroup } from '@angular/forms'
import { AppService } from '../../app.service'
import { TableActions } from '../shared.interfaces'
import { Observable } from 'rxjs'
import { selectLoading } from '../../store/reducers/layout.reducer'

import {
	NgbCalendar,
	NgbDate,
	NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap'
import { DatePipe } from '@angular/common'
import {
	selectUserDateFormat,
	User,
	UserState
} from '../../store/reducers/user.reducer'
import { ValidateAllFormFields } from '../helper.functions'

@Component({
	selector: 'advanced-search',
	templateUrl: './advanced-search.component.html',
	styleUrls: ['./advanced-search.component.scss'],
	providers: [DatePipe]
})
export class AdvancedSearch implements OnInit {
	@Input() resources: any
	@Input() dateRange: any
	@Input() ladda$: Observable<any>
	@Output() onSearching = new EventEmitter()
	@ViewChild('expandArrow') expandArrow: ElementRef
	searchForm: FormGroup
	format$: Observable<User>
	collapsed: boolean = true
	hoveredDate: NgbDate | null = null
	_ = _

	constructor(
		private store$: Store<AppState>,
		private userStore$: Store<UserState>,
		private formBuilder: FormBuilder,
		public appService: AppService,
		private datePipe: DatePipe,
		public formatter: NgbDateParserFormatter,
		private calendar: NgbCalendar
	) {
		this.format$ = this.userStore$.pipe(
			select(selectUserDateFormat('short', false))
		)
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['resources']) {
			_.forOwn(this.resources, (val, key) => {
				if (this.searchForm) {
					this.searchForm.get(key).setValue(val.value)
				}
			})
		}
	}

	// Datepicker
	onDateSelection(date: NgbDate) {
		if (!this.searchForm.get('startDate').value && !this.searchForm.get('endDate').value) {
			this.searchForm.get('startDate').setValue(date)
		} else if (
			this.searchForm.get('startDate').value &&
			!this.searchForm.get('endDate').value
		) {
			this.searchForm.get('endDate').setValue(date)
		} else {
			this.searchForm.get('endDate').setValue(null)
			this.searchForm.get('startDate').setValue(date)
		}
		this.searchForm.updateValueAndValidity()
	}

	isHovered(date: NgbDate) {
		return (
			this.searchForm.get('startDate').value &&
			!this.searchForm.get('endDate').value &&
			this.hoveredDate &&
			date.after(this.searchForm.get('startDate').value) &&
			date.before(this.hoveredDate)
		)
	}

	isInside(date: NgbDate) {
		return this.searchForm.get('endDate').value && date.after(this.searchForm.get('startDate').value) && date.before(this.searchForm.get('endDate').value)
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.searchForm.get('startDate').value) ||
			(this.searchForm.get('endDate').value && date.equals(this.searchForm.get('endDate').value)) ||
			this.isInside(date) ||
			this.isHovered(date)
		)
	}
	
	checkResourcesLength(){
		return Object.keys(this.resources).length
	}

	ngOnInit(): void {
		this.ladda$ = this.store$.pipe(select(selectLoading('advancedLoading')))
		this.searchForm = this.formBuilder.group({})

		if (this.dateRange) {
			if (this.dateRange.start_date) {
				this.searchForm.get('startDate').setValue(NgbDate.from(
					this.formatter.parse(this.dateRange.start_date)
				))
			}
			if (this.dateRange.end_date) {
				this.searchForm.get('endDate').setValue(NgbDate.from(
					this.formatter.parse(this.dateRange.start_date)
				))
			}
		}

		_.forOwn(this.resources, (val, key) => {
			this.searchForm.addControl(
				key,
				this.formBuilder.control(
					val.type === 'multiSelect'
						? val.value
							? _.map(val.value, (id) => {
									if (val.key) {
										return _.find(val.all, (item) => item[val.key] == id)
									} else {
										return _.find(val.all, (item) => item.id == id)
									}
							  })
							: []
						: val.value || null,
					val.validators ? [...val.validators] : []
				)
			)
			if (val.type == 'dateRange'){
				this.searchForm.addControl('startDate', this.formBuilder.control(null))
				this.searchForm.addControl('endDate', this.formBuilder.control(null))
			}
			val.control = this.searchForm.controls[key]
		})

		this.store$.pipe(select(selectLoading('advancedLoading'))).subscribe((state)=>{
			//When i'm searching disable all the fields
			let controls = this.searchForm.controls
			_.forOwn(controls, (val, key) => {
				if(state){
					this.searchForm.get(key)?.disable()
				} else{
					_.forOwn(controls, (val, key) => {
						this.searchForm.get(key)?.enable()
					})
				}
			})
		})
	}
	onSubmit() {
		if (!this.searchForm.valid) {
			ValidateAllFormFields(this.searchForm)
			return false
		}

		let payload = { ...this.searchForm.value }
		if (this.dateRange) {
			if (this.searchForm.get('startDate').value) {
				payload['start_date'] = this.datePipe.transform(
					new Date(
						this.searchForm.get('startDate').value.year,
						this.searchForm.get('startDate').value.month - 1,
						this.searchForm.get('startDate').value.day
					).setHours(0, 0, 0, 0),
					'YYYY-MM-dd HH:mm:ss'
				)
				delete payload.startDate

			}
			if (this.searchForm.get('endDate').value) {
				payload['end_date'] = this.datePipe.transform(
					new Date(
						this.searchForm.get('endDate').value.year,
						this.searchForm.get('endDate').value.month - 1,
						this.searchForm.get('endDate').value.day
					).setHours(23, 59, 59, 999),
					'YYYY-MM-dd HH:mm:ss'
				)
				delete payload.endDate
			}
		}

		this.onSearching.emit({
			values: payload,
			action: TableActions.SEARCHING
		})
	}

	collapse() {
		this.collapsed = true
	}

	public collapseAndFocus() {
		if (!this.collapsed) {
			this.expandArrow.nativeElement.focus()
		}
		this.collapsed = true
	}

	resetForm() {
		this.searchForm.reset()
		this.searchForm.markAsUntouched()
		if(this.searchForm.get('startDate') != null){
			this.searchForm.get('startDate').setValue(null)
			this.searchForm.get('endDate').setValue(null)
		}
		this.onSearching.emit({
			values: {
				...this.searchForm.value,
				start_date:this.searchForm.get('startDate') != null ? this.formatter.format(this.searchForm.get('startDate').value) : null,
				end_date: this.searchForm.get('endDate') != null ? this.formatter.format(this.searchForm.get('endDate').value) : null
			},
			action: TableActions.CLEARING
		})
	}
}
