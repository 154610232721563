<form
	class="actions mt-20"
	[formGroup]="searchForm"
	autocomplete="off"
	(ngSubmit)="onSubmit()"
	(lostFocus)="collapse()"
	(clickOutside)="collapse()"
>
	<div class="input-container" *ngFor="let mainInput of resources | keys">
		<!-- main input -->
		<ng-container *ngIf="mainInput.value.main">
			<label [for]="mainInput.key + 'main_search'">
				{{ mainInput.value.label | translate }}
			</label>
			<div class="w-100 d-flex">
				<div class="main-search">
					<input
						[id]="mainInput.key + 'main_search'"
						[formControlName]="mainInput.key"
						class="dark main-search-input"
						[type]="mainInput.value.type"
						[placeholder]="mainInput.value.placeholder | translate"
					/>
					<div
						#expandArrow
						class="arrow"
						[class.expanded]="!collapsed"
						tabindex="0"
						role="button"
						[attr.aria-expanded]="!collapsed"
						(click)="collapsed = !collapsed"
						(keydown.enter)="collapsed = !collapsed"
						*ngIf="checkResourcesLength() !== 1"
					>
						<span aria-hidden="true" class="material-icons-outlined">
							keyboard_arrow_down
						</span>
						<span class="sr-only" translate>Open filters</span>
					</div>
					<div
						[hidden]="collapsed || checkResourcesLength() === 1"
						[attr.aria-hidden]="collapsed"
						class="expand-content"
					>
						<fieldset [disabled]="ladda$ | async" id="search-row">
							<legend class="sr-only" translate>Filters container</legend>
							<div>
								<div class="row">
									<ng-container *ngFor="let entry of resources | keys">
										<div [ngClass]="{'col-lg-8': entry.value.type == 'dateRange'}" class="col-lg-4" *ngIf="!entry.value.main">
											<div
												class="mb-20"
												[ngSwitch]="entry.value.type"
												[class.align-top]="entry.value.type !== 'checkbox'"
											>
												<div
													class="input-container custom-checkbox"
													*ngSwitchCase="'checkbox'"
												>
													<input
														[id]="entry.key + '_search'"
														[formControlName]="entry.key"
														type="checkbox"
													/>
													<label [for]="entry.key + '_search'">
														{{ entry.value.label | translate }}
														<span aria-hidden="true" class="check"></span>
													</label>
												</div>

												<div *ngSwitchCase="'dateRange'">
													<div *ngIf="dateRange">
														<div class="dp-hide">
															<span id="dp-helper" class="sr-only" translate>Press Enter to open the datepicker</span>
															<input
																id="datepicker-calendar"
																name="datepicker"
																class="custom-datepicker form-control"
																ngbDatepicker
																#datepicker="ngbDatepicker"
																[autoClose]="'outside'"
																(dateSelect)="onDateSelection($event)"
																[displayMonths]="2"
																[dayTemplate]="t"
																outsideDays="hidden"
																[startDate]="fromDate!"
																tabindex="-1"
															/>
															<ng-template #t let-date let-focused="focused">
																<span
																	class="custom-day"
																	[class.focused]="focused"
																	[class.range]="isRange(date)"
																	[class.faded]="
																		isHovered(date) || isInside(date)
																	"
																	(mouseenter)="hoveredDate = date"
																	(mouseleave)="hoveredDate = null"
																>
																	{{ date.day }}
																</span>
															</ng-template>
														</div>
														<div class="align-top">
															<div class="input-container">
																<label for="fromDate" translate>{{ dateRange.label | translate }} <span translate>(optional)</span> <span class="sr-only" translate>(Start date)</span></label>
															</div>
															<div class="row">
																<div class="col-lg-6 mb-20">
																	<div class="input-group">
																		<input
																			#dpFromDate
																			id="fromDate"
																			class="form-control dark"
																			[placeholder]="format$ | async"
																			name="dpFromDate"
																			type="text"
																			[ngClass]="{'is-invalid': !searchForm.get('startDate').valid && !searchForm.get('startDate').disabled}"
																			aria-describedby="start_date-error"
																			ngbDatepicker
																			formControlName="startDate"
																			[maxDate]="searchForm.get('endDate').value"
																		/>
																		<div  tabindex="0"  role="button" class="input-group-append"  (click)="datepicker.toggle()" (keydown.enter)="datepicker.toggle()">
																				<span class="input-group-text" style="padding: 0 5px">
																					<span class="material-icons-outlined">event
																						<span class="sr-only" translate>Open Calendar</span>
																					</span>
																				</span>
																		</div>
																	</div>
																	<error-field
																		inputName="{{'Start Date' | translate}}"
																		inputKey="start_date"
																		[error]="searchForm.get('startDate')"
																	></error-field>

																</div>
																<div class="col-lg-6 mb-20">
																	<div class="input-container">
																		<div class="input-group">
																			<label class="sr-only" for="toDate" translate>{{ dateRange.label | translate }} <span class="sr-only" translate>(End date)</span></label>
																			<input
																				#dpToDate
																				id="toDate"
																				class="form-control dark"
																				[placeholder]="format$ | async"
																				[ngClass]="{'is-invalid': !searchForm.get('endDate').valid && !searchForm.get('endDate').disabled}"
																				aria-describedby="end_date-error"
																				name="dpToDate"
																				type="text"
																				ngbDatepicker
																				formControlName="endDate"
																				[minDate]="searchForm.get('startDate').value"
																			/>
																			<div  tabindex="0"  role="button" class="input-group-append"  (click)="datepicker.toggle()" (keydown.enter)="datepicker.toggle()">
																				<span class="input-group-text" style="padding: 0 5px">
																					<span class="material-icons-outlined">event
																						<span class="sr-only" translate>Open Calendar</span>
																				</span>
																				</span>
																			</div>
																		</div>
																		<error-field
																			inputName="{{'End Date' | translate}}"
																			inputKey="end_date"
																			[error]="searchForm.get('endDate')"
																		></error-field>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<custom-form-input
													*ngSwitchDefault
													[formControlName]="entry.key"
													[type]="entry.value.type"
													[id]="entry.key + '_search'"
													[placeholder]="entry.value.placeholder | translate"
													[css]="'form-control dark'"
													[label]="entry.value.label | translate"
												></custom-form-input>
												<div
													class="input-container"
													*ngSwitchCase="'singleSelect'"
												>
													<label [for]="entry.key + '_search'">
														{{ entry.value.label | translate }} <span translate>(optional)</span>
													</label>

													<ng-select
															selectAccessible
															class="custom"
															[id]="entry.key"
															formControlName="{{ entry.key }}"
															[items]="entry.value.all"
															[bindLabel]="entry.value.val || 'name'"
															bindValue="{{ entry.value.key || 'id' }}"
															[readonly]="!entry.value.all"
															[clearable]="true"
															[labelForId]="entry.key + '_search'"
															[closeOnSelect]="false"
															placeholder="{{
															entry.value.placeholder | translate
														}}"
													>
														<ng-template ng-option-tmp let-item="item">
															{{
															_.isObject(item[entry.value.val || 'name'])
																	? (item[entry.value.val || 'name']
																			| getLangParam
																			| async)
																	: (item[entry.value.val || 'name']
																			| translate)
															}}
														</ng-template>
														<!-- overrided selected value display -->
														<ng-template
																ng-label-tmp
																let-item="item"
																let-clear="clear"
														>
															<span class="ng-value-label">
																{{
																_.isObject(item[entry.value.val || 'name'])
																		? (item[entry.value.val || 'name']
																				| getLangParam
																				| async)
																		: (item[entry.value.val || 'name']
																				| translate)
																}}
															</span>
														</ng-template>
													</ng-select>
												</div>
												<div
													class="input-container multi-select custom-ng-select"
													*ngSwitchCase="'multiSelect'"
												>
													<label for="{{ entry.key }}-id">
														{{ entry.value.label | translate }} <span translate>(optional)</span>
													</label>
													<!-- I have to use [value]="id" to make it works, bindId="id" doesn't work -->
													<ng-select
														class="custom text-capitalize"
														[id]="entry.key"
														formControlName="{{ entry.key }}"
														selectAccessible
														[searchable]="false"
														[readonly]="entry.value.disabled"
														[items]="entry.value.all"
														[bindLabel]="entry.value.val || 'name'"
														bindValue="{{ entry.value.key || 'id' }}"
														[labelForId]="entry.key + '-id'"
														[multiple]="true"
														[closeOnSelect]="false"
														[clearable]="false"
														placeholder="{{
															entry.value.placeholder | translate
														}}"
													>
														<ng-template ng-option-tmp let-item="item">
															{{
																_.isObject(item[entry.value.val || 'name'])
																	? (item[entry.value.val || 'name']
																	  | getLangParam
																	  | async)
																	: (item[entry.value.val || 'name']
																	  | translate)
															}}
														</ng-template>
														<!-- overrided selected value display -->
														<ng-template
															ng-label-tmp
															let-item="item"
															let-clear="clear"
														>
															<span class="ng-value-label">
																{{
																	_.isObject(item[entry.value.val || 'name'])
																		? (item[entry.value.val || 'name']
																		  | getLangParam
																		  | async)
																		: (item[entry.value.val || 'name']
																		  | translate)
																}}
															</span>
															<span
																class="ng-value-icon right"
																(click)="clear(item); $event.stopPropagation()"
																aria-hidden="true"
															>
																×
															</span>
														</ng-template>
													</ng-select>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
								<div class="d-flex justify-content-end">
									<custom-button
										[ladda]="ladda$"
										css="no-border mr-20"
										(onClick)="resetForm()"
										[label]="'Reset' | translate"
									></custom-button>
									<custom-button
										[ladda]="ladda$"
										css="outline secondary"
										type="submit"
										[label]="'Apply Filters' | translate"
									></custom-button>
								</div>
							</div>
						</fieldset>
					</div>
				</div>

				<custom-button
					[ladda]="ladda$"
					css="full primary"
					type="submit"
					[label]="'Search' | translate"
				></custom-button>
			</div>
		</ng-container>
	</div>
</form>
