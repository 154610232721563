import {
	AbstractControl,
	FormArray,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from "@angular/forms";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { at, forOwn, isNil, isObject, isString, reduce, forEach, has } from "lodash-es";
import moment from "moment";
import "moment-timezone";
import { distinctUntilChanged } from "rxjs/operators";
import { Filter } from "../dashboard/segments/segments.interfaces";
import { JQueryBuilder } from "./jQuery-QueryBuilder/jquery-builder.interface";
import CONDITIONS = JQueryBuilder.CONDITIONS;

export function mergeDeep(target, ...sources) {
	if (!sources.length) return target
	const source = sources.shift()

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} })
				mergeDeep(target[key], source[key])
			} else {
				Object.assign(target, { [key]: source[key] })
			}
		}
	}

	return mergeDeep(target, ...sources)
}

export function clearState(reducer) {
	return function (state, action) {
		if (action.type === '[App] Clear App') {
			state = {
				layoutState: {
					lang: state.layoutState.lang,
					error: state.layoutState.error,
					loading: state.layoutState.loading
				}
			}
		}

		return reducer(state, action)
	}
}

function convertTimezone(datetime, format, fromTimezone, toTimezone) {
	return moment.tz(datetime, toTimezone).format(format)
}

export function convertToUTC(
	datetime,
	format = 'YYYY-MM-DD HH:mm:ss',
	sourceTimezone?: string
) {
	if (isNil(datetime)) {
		return null
	}

	return convertTimezone(
		datetime,
		format,
		sourceTimezone || moment.tz.guess(),
		'UTC'
	)
}

export function creditCardValidator(
	control: AbstractControl
): { [key: string]: string } | null {
	if (control.value) {
		let type = /^5[1-5]/.test(control.value)
			? 'mastercard'
			: /^4/.test(control.value)
			? 'visa'
			: /^3[47]/.test(control.value)
			? 'amex'
			: /^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(
					control.value
			  )
			? 'discover'
			: undefined

		if (!type) {
			return {
				custom: _(
					'Credit card must be a valid Amex, Visa, Discover, or Master Card'
				)
			}
		}
	}

	return null
}

export function exactDigits(val: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value) {
			if (control.value.length !== val) {
				return { exact_number: val }
			}
		}

		return null
	}
}


export function comparisonValidator(start_date_control:string,end_date_control: string, date_format:string,start_date_time_control:string = '',end_date_time_control: string = '') : ValidatorFn{
	return (group: FormGroup): ValidationErrors => {

		const start_at = group.controls[start_date_control].value;
		const end_at = group.controls[end_date_control].value;
		const start_at_time = group.controls[start_date_time_control]?.value;
		const end_at_time = group.controls[end_date_time_control]?.value;
		let moment_start = null
		let moment_end = null

		if(isString(start_at) && start_at.includes('T')){
			moment_start = moment(start_at.split('T')[0])
		} else if(isString(start_at)){
			moment_start = moment(start_at,date_format)
		} else {
			moment_start = moment([start_at?.year,start_at?.month-1,start_at?.day])
		}

		if(start_at_time){
			moment_start = moment_start.set(start_at_time)
		}

		if(isString(end_at) && end_at.includes('T')){
			moment_end = moment(end_at.split('T')[0])
		} else if(isString(end_at)){
			moment_end = moment(end_at,date_format)
		} else {
			moment_end = moment([end_at?.year,end_at?.month-1,end_at?.day])
		}

		if(end_at_time){
			moment_end = moment_end.set(end_at_time)
		}

		if(moment_end.isValid() && moment_start.isValid()){
			let min_diff = moment_end.diff(moment_start,'minutes')

			if(min_diff<0){
				group.controls[end_date_control].setErrors({bigger_than_min: true})
				group.controls[start_date_control].setErrors({lower_than_max: true})
			} else {
				group.controls[end_date_control].setErrors(null)
				group.controls[start_date_control].setErrors(null)
			}
		}
		return;
	};
}

export function dateValidator(format: string): ValidatorFn {

	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value) {

			//Date is coming from DB
			if(isString(control.value) && control.value.includes('T')){
				return null
			}
			let user_format_date = moment(control.value, format,true)
			if(type(control.value) == 'Object'){
				return null
			}
			else if (user_format_date.isValid()) {
				return null
			} else {
				return 	{ 'pattern': true }
			}
		}

		return null
	}

}
export function type(obj){
	return Object.prototype.toString.call(obj).slice(8, -1);
}

export function emptyLangs(formValues: any) {
	let empty = true
	forOwn(formValues, (val) => {
		if (!isNil(val) && val !== '') {
			empty = false
		}
	})

	return empty
}

export function getProgramLanguage(
	formGroups: any[],
	only_program_language = false,
	provided_list = [],
	setReq = false
) {
	const currentProgram = localStorage.getItem('program')
		? JSON.parse(localStorage.getItem('program'))[0]
		: null

	let default_lang_code = ''
	let lang_list = []


	if (currentProgram && only_program_language) {
		lang_list = currentProgram.languages.map((lang) => {
			if (lang.is_default === 1) {
				default_lang_code = lang.code
			}
			return { code: lang.code, name: lang.name, required: !setReq ? lang.is_default : false }
		})
	} else {
		lang_list = [{ code: 'en-CA' }, { code: 'fr-CA' }, { code: 'en-US' }]
		default_lang_code = 'en-CA'
	}

	if(provided_list.length){
		provided_list = provided_list.map((lang) => {
			return { code: lang.code, name: lang.name, required: lang.is_default }
		})
		lang_list = provided_list
	}

	formGroups.forEach((formGroup) => {
		lang_list.forEach((lang) => {

			;(<FormGroup>formGroup.control).addControl(
				lang.code,
				new FormControl('', lang.required  ? [Validators.required] : [])
			)
		})

		const formDefaultLang = formGroup.control.controls[default_lang_code]

		if (!formGroup.required) {
			formGroup.control.valueChanges
				.pipe(
					distinctUntilChanged(
						(prev, current) => JSON.stringify(prev) === JSON.stringify(current)
					)
				)
				.subscribe((value) => {
					let changed = false
					forOwn(value, (val, key) => {
						if (
							key !== default_lang_code &&
							val !== '' &&
							value[default_lang_code] === ''
						) {
							changed = true
							return
						}
					})
					if (changed) {
						formDefaultLang.setValidators(Validators.required)
						formDefaultLang.updateValueAndValidity()
					} else {
						formDefaultLang.clearValidators()
					}
				})
		} else {
			formDefaultLang.setValidators(Validators.required)
		}
	})

	return { lang_list: lang_list, default_lang: default_lang_code }
}

export function convertFromUTC(datetime, format = 'YYYY-MM-DD HH:mm:ss') {
	if (isNil(datetime)) {
		return null
	}

	return convertTimezone(datetime, format, 'UTC', moment.tz.guess())
}

export function validateRadio(c: FormControl) {
	let err = {
		required: {}
	}
	return !c.value ? err : null
}

export function orderByDate(data: any[], order) {
	return data.sort((a, b) => {
		let dateA: any = new Date(a.created_at)
		let dateB: any = new Date(b.created_at)

		if (order === 'desc') {
			return dateB - dateA
		} else {
			return dateA - dateB
		}
	})
}
//TODO When a new report is added map it here
export const reports_mapping = {
	discretionary_campaign_reports: {
		permission: 'generateDiscretionaryCampaignReport'
	},
	flex_campaign_reports: {
		permission: 'generateFlexCampaignReport'
	},
	incentive_campaign_reports: {
		permission: 'generateIncentiveCampaignReport'
	},
	order_reports: {
		permission: 'generateOrderReport'
	},
	merchandise_reports: {
		permission: 'generateMerchandiseReport'
	},
	ecard_reports: {
		permission: 'generateEcardReport'
	},
	member_transaction_reports: {
		permission: 'generateMemberTransactionReport'
	},
	member_reports: {
		permission: 'generateMembersReport'
	},
	accounts_balance_reports: {
		permission: 'generateAccountsReport'
	},
	account_transaction_reports: {
		permission: 'generateAccountTransactionReport'
	},
	member_balance_reports: {
		permission: 'generateMemberBalanceReport'
	},
	current_fraud_orders_report: {
		permission: 'generateCurrentFraudOrdersReport'
	},
	current_locked_members_report: {
		permission: 'generateCurrentLockedMembersReport'
	},
	fraud_orders_report: {
		permission: 'generateFraudOrdersReport'
	},
	locked_members_report: {
		permission: 'generateLockedMembersReport'
	},
	user_access_report: {
		permission: 'generateUserAccessReport'
	},
	order_fee_report: {
		permission: 'generateOrderFeeReport'
	},
	payment_transaction_report: {
		permission: 'generatePaymentTransactionReport'
	},
	member_close_to_cap_reports:{
		permission: 'generateMemberCloseToCapReport'
	},
	transaction_cap_exception_reports:{
		permission: 'generateTransactionCapExceptionReport'
	}
}

export function geRouteByModuleName(key: string) {
	const nav = [
		{
			name: 'System',
			icon: 'settings',
			code: 'system'
		},
		{
			name: 'Roles',
			link: 'roles',
			code: 'roles'
		},
		{
			link: 'system_users',
			code: 'system_users',
			navName: _('System Users'),
			name: 'System Access'
		},
		{
			icon: 'group',
			code: 'customer_management',
			name: 'Customer Management',
			navName: _('Customers')
		},
		{
			icon: 'local_shipping',
			code: 'fulfillment_management',
			navName: _('Fulfillment'),
			name: 'Fulfillment Management'
		},
		{
			icon: 'emoji_events',
			code: 'program_management',
			name: 'Program Management',
			navName: _('Programs')
		},
		{
			link: 'programs',
			name: 'Program Details',
			code: 'program_details'
		},
		{
			name: 'Teams',
			link: 'teams',
			code: 'teams'
		},
		{
			name: 'Tiers',
			link: 'tiers',
			code: 'tiers',
			hidden: false
		},
		{
			name: 'Transaction Labels',
			code: 'transaction_labels',
			link: 'transaction_labels',
			hidden: false
		},
		{
			name: 'Promotions',
			link: 'promotions',
			code: 'promotions',
			hidden: false
		},
		{
			name: 'Campaigns',
			code: 'campaigns',
			hidden: false
		},
		{
			name: 'Flex Campaigns',
			link: 'campaigns/flex_campaigns',
			code: 'flex_campaigns',
			hidden: true
		},
		{
			name: 'Nomination Campaigns',
			link: 'campaigns/nomination_campaigns',
			code: 'nomination_campaigns',
			hidden: true
		},
		{
			name: 'Incentives Campaigns',
			link: 'campaigns/incentives_campaigns',
			code: 'incentives_campaigns',
			hidden: true
		},
		{
			name: 'Discretionary Campaigns',
			link: 'campaigns/discretionary_campaigns',
			code: 'discretionary_campaigns',
			hidden: true
		},
		{
			name: 'Base Earn Campaign',
			navName: _('Base Earn'),
			link: 'campaigns/base',
			code: 'base',
			hidden: false
		},
		{
			name: 'Program Components',
			code: 'program_components',
			hidden: false
		},
		{
			name: 'Ecards',
			link: 'program_components/ecards',
			code: 'ecards',
			hidden: true
		},
		{
			name: 'Shop',
			link: 'program_components/shop',
			code: 'shop',
			hidden: false
		},
		{
			name: 'Ascenda',
			link: 'program_components/ascenda',
			code: 'ascenda',
			hidden: false
		},
		{
			name: 'Reward Management',
			link: 'reward_management',
			code: 'reward_management',
			hidden: true
		},
		{
			name: 'Sale Channels',
			link: 'sale_channels',
			code: 'sale_channels'
		},
		{
			name: 'Sale Channel Actions',
			link: 'sale_channel_actions',
			code: 'sale_channel_actions'
		},
		{
			name: 'User Registration',
			navName: _('Registration & Login'),
			link: 'user_registration',
			code: 'user_registration',
			hidden: false
		},
		{
			name: 'Bin Range Configuration',
			navName: _('BIN Configuration'),
			link: 'bin_range_configuration',
			code: 'bin_range_configuration',
			hidden: false
		},
		{
			name: 'Additional Fees',
			link: 'additional_fees',
			code: 'additional_fees',
			hidden: false
		},
		{
			name: 'Terms',
			link: 'terms',
			code: 'terms',
			hidden: true
		},
		{
			name: 'Notifications',
			link: 'notifications',
			code: 'notifications',
			hidden: false
		},
		{
			name: 'Content Management',
			code: 'content_management',
			hidden: false
		},
		{
			name: 'Carousels',
			link: 'content_management/carousels',
			code: 'carousels',
			hidden: false
		},
		{
			name: 'Page Management',
			navName: _('Pages'),
			link: 'content_management/page_management',
			code: 'page_management',
			hidden: false
		},
		{
			name: 'Navigation Management',
			navName: _('Menus'),
			link: 'content_management/navigation_management',
			code: 'navigation_management',
			hidden: false
		},
		{
			name: 'External Redemption',
			link: 'external_redemption',
			code: 'external_redemption',
			hidden: false
		},
		{
			name: 'Purchase Erasers',
			link: 'purchase_eraser',
			code: 'purchase_eraser',
			hidden: false
		},
		{
			name: 'Pricing Calculator',
			navName: _('Pricing Calculators'),
			link: 'pricing_calculator',
			code: 'pricing_calculator',
			hidden: false
		},
		{
			name: 'Order Management',
			code: 'order_management'
		},
		{
			name: 'Orders',
			link: 'orders',
			code: 'orders'
		},
		{
			name: 'Order Notes'
		},
		{
			name: 'Order Item Notes'
		},
		{
			name: 'Member Notes'
		},
		{
			name: 'Place Order',
			link: 'place_order',
			code: 'place_order',
			hidden: true
		},
		{
			name: 'Products',
			link: 'products',
			code: 'products',
			hidden: false
		},
		{
			name: 'Suppliers',
			link: 'suppliers',
			code: 'suppliers',
			hidden: false
		},
		{
			name: 'Brands',
			link: 'brands',
			code: 'brands',
			hidden: false
		},
		{
			name: 'Product Collections',
			link: 'product_collections',
			code: 'product_collections',
			hidden: false
		},
		{
			name: 'Product Labels',
			link: 'product_labels',
			code: 'product_labels',
			hidden: false
		},
		{
			name: 'Vendor',
			navName: _('Vendors'),
			link: 'vendors',
			code: 'vendors',
			hidden: false
		},
		{
			name: 'Members',
			link: 'members',
			code: 'members'
		},
		{
			name: 'My Profile',
			navName: _('Profile'),
			link: 'profile',
			code: 'profile',
			hidden: true
		},
		{
			name: 'Partners',
			link: 'partners',
			code: 'partners',
			hidden: false
		},
		{
			name: 'Customers',
			link: 'customers',
			code: 'customers'
		},
		{
			name: 'Segments',
			link: 'segments',
			code: 'segments',
			hidden: false
		},
		{
			name: 'Pending Action',
			link: 'pending_action',
			code: 'pending_action',
			hidden: false
		},
		{
			name: 'Authorized Redeemers',
			link: 'authorized_redeemers',
			code: 'authorized_redeemers',
			hidden: false
		},
		{
			icon: 'lock',
			code: 'fraud_transactions',
			navName: _('Fraud Transactions'),
			name: 'Fraud Transactions',
			hidden: false
		},
		{
			name: 'Locked Members',
			link: 'locked_members',
			code: 'locked_members',
			hidden: false
		},
		{
			name: 'Locked Orders',
			link: 'locked_orders',
			code: 'locked_orders',
			hidden: false
		},
		{
			name: 'Reports',
			icon: 'insert_chart',
			link: 'reports',
			code: 'reports',
			hidden: false
		}
	]

	return nav.find((item) => item.name === key) || ({} as any)
}

export function multiselectSearch(search: any, items: any[]) {
	let filteredItems = []

	items.forEach((item) => {
		let match: boolean = true
		forOwn(search, (val, key) => {
			if (isString(val)) {
				if (!item[key].toLowerCase().includes(val.toLowerCase())) {
					match = false
				}
			} else if (isObject(val)) {
				let currentProp = at(item, [val.path])[0]
				if (!currentProp || currentProp != val.value) {
					match = false
				}
			}
		})
		if (match) {
			filteredItems.push(item)
		}
	})

	return { items: filteredItems, fromApi: search.fromApi }
}

export function validateArrayLength(c: FormControl) {
	let err = {
		required: {}
	}
	return !c.value?.length ? err : null
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
	return (formGroup: FormGroup) => {
		const control = formGroup.controls[controlName]
		const matchingControl = formGroup.controls[matchingControlName]

		if (matchingControl.errors && !matchingControl.errors.mustMatch) {
			// return if another validator has already found an error on the matchingControl
			return
		}

		// set error on matchingControl if validation fails
		if (control.value !== matchingControl.value) {
			matchingControl.setErrors({ mustMatch: true })
		} else {
			matchingControl.setErrors(null)
		}
	}
}

export const customValidators = {
	password: customPatternValid({
		pattern: /^(?=.*\d)(?=.*[!@#$%^&*£;:.,_\[\]\-\?\\\/\(\)\=])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
		msg: _('Password must contain at least eight characters (8) including 1 lower case and 1 capital letter, 1 number and 1 special character')
	}),
}

export function customPatternValid(config: any): ValidatorFn {
	return (control: FormControl) => {
		let urlRegEx: RegExp = config.pattern;
		if (control.value && !control.value.match(urlRegEx)) {
			return {
				invalidMsg: config.msg
			};
		} else {
			return null;
		}
	};
}

export function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}

export function closestByClass(el, clazz) {
	// Traverse the DOM up with a while loop
	while (!el.className?.includes(clazz)) {
		// Increment the loop to the parent node
		el = el.parentNode
		if (!el) {
			return null
		}
	}
	return el
}

export function ValidateAllFormFields(formGroup: FormGroup | FormArray) {
	Object.keys(formGroup.controls).forEach((field) => {
		const control = formGroup.get(field)
		if (control instanceof FormControl) {
			control.markAsTouched({ onlySelf: true })
		} else if (control instanceof FormGroup || control instanceof FormArray) {
			ValidateAllFormFields(control)
		}
	})
}

export function getQueryFilters(filters: Array<Filter>): Array<JQueryBuilder.Filters> {

  let build: Array<JQueryBuilder.Filters> = [];

  let operators: any = {
    integer: [
      JQueryBuilder.OPERATORS.GREATER, JQueryBuilder.OPERATORS.GREATER_OR_EQUAL,
      JQueryBuilder.OPERATORS.LESS, JQueryBuilder.OPERATORS.LESS_OR_EQUAL,
      JQueryBuilder.OPERATORS.EQUAL, JQueryBuilder.OPERATORS.NOT_EQUAL
    ],
    string: [
      JQueryBuilder.OPERATORS.EQUAL, JQueryBuilder.OPERATORS.NOT_EQUAL
    ]
  };

  operators.float = operators.integer;
  operators.boolean = operators.string;
  operators.select = operators.string;
  operators.datetime = operators.integer;

  // TODO: UI FOR IN NOT_IN NEEDS TO BE ADDED

  filters.forEach((filter: any, index: number) => {

    let currentFilter: any = {
      id: filter.id,
      label: filter.display_name,
      type: filter.data_type === "float" ? "integer" : filter.data_type,
      validation: {
        allow_empty_value: false
      },
      operators: operators[filter.data_type]
    };
    if (filter.values) {
      currentFilter.input = "select";
      currentFilter.operators = operators.select;
      currentFilter.type = "integer";
      currentFilter.values = reduce(filter.values, function(hash: Array<string>, option: any) {
        hash[option.id] = option.name;
        return hash;
      }, {});
    }
    if (filter.data_type === "datetime") {
      currentFilter.validation = {
        allow_empty_value: false
      };
      currentFilter.plugin = "datepicker";
      currentFilter.plugin_config = {
        format: "mm/dd/yyyy",
        todayBtn: "linked",
        todayHighlight: true,
				language: localStorage.getItem('lang') || 'en-CA',
        autoclose: true
      };
    }
    if (filter.data_type === "boolean") {
      currentFilter.input = JQueryBuilder.FILTER_INPUTS.SELECT;
      currentFilter.values = {
        true: "true",
        false: "false"
      };
    }

    build.push(currentFilter);
  });

  return build;
}

export function _apiToSegmentQuery(query: any): any {

  let result: any = {
    condition: query[0].gate ? query[0].gate : query[0].rules[0].gate,
    rules: []
  };

  let recursiveChildren = (childRules) => {

    let children = [];
    childRules.forEach((filter: any) => {

      if (filter.rules) {
        children.push({
          condition: filter.rules[0].gate ? filter.rules[0].gate : filter.rules[0].rules[0].gate,
          rules: recursiveChildren(filter.rules)
        });
      } else {
        let currentGate: any = {
          id: filter.filter.id,
          operator: filter.operator.operator,
          value: filter.value
        };
        children.push(currentGate);
      }

    });

    return children;
  };

  query.forEach((filter: any) => {

    if (filter.rules) {
      result.rules.push({
        condition: filter.rules[0].gate ? filter.rules[0].gate : filter.rules[0].rules[0].gate,
        rules: recursiveChildren(filter.rules)
      });
    } else {
      let currentGate: any = {
        id: filter.filter.id,
        operator: filter.operator.operator,
        value: filter.value
      };
      result.rules.push(currentGate);
    }

  });

  return result;
}

export function queryToSegmentationAPI(query: JQueryBuilder.Group): Array<JQueryBuilder.APISegmentations> {
  let lastCondition: CONDITIONS = query.condition;
  let build: any[] = [];

  let nestedRules = (rule, gate) => {
    rule.rules.forEach((cRule, index) => {
      let cGate: any;
      if (cRule.condition) {
        cGate = {
          rules: []
        };
        lastCondition = rule.condition;
        nestedRules(cRule, cGate);
        gate.rules.push(cGate);
      } else {
        cGate = {
          group_filter_id: cRule.id,
          operator: cRule.operator,
          gate: rule.condition,
          value: cRule.value.toString()
        };
        if ((rule.rules.length > 1) && (index === rule.rules.length - 1)) {
          cGate.gate = lastCondition;
        }
        gate.rules.push({
          gate: cGate,
          rules: []
        });
      }
    });
  };

  query.rules.forEach((rule) => {
    let gate: any;
    if (rule.condition) {
      gate = {
        rules: []
      };
      nestedRules(rule, gate);
      lastCondition = query.condition;
      build.push(gate);
    } else {
      gate = {
        group_filter_id: rule.id,
        operator: rule.operator,
        gate: lastCondition,
        value: rule.value.toString()
      };
      build.push({
        gate: gate
      });
    }
  });

  return build;
}

export function createUuid(type:boolean){
	if(!type){
		return '00000000-0000-0000-0000-000000000000';
	}
	function _p8(s?): string {
		let p = (Math.random().toString(16) + '000000000').substr(2, 8);
		return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
	}
	return _p8() + _p8(true) + _p8(true) + _p8();
}

export function getPlaceHoldersForRoute(obj, key){
	if (has(obj, key)){
		return [obj[key]][0].placeholders;
	}
	let res = [];
	forEach(obj, (v) => {
		if (typeof v === 'object' && (v = getPlaceHoldersForRoute(v, key)).length)
			res.push.apply(res, v);
	});
	//Add this to conver JSON string to object
	forEach(res,(ph)=>{
		if(isString(ph.name)){
			ph.name = JSON.parse(ph.name)
		}
	})
	return res;
}
