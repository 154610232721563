<div
	id="{{ inputKey ? inputKey + '-error' : inputName + '-error' }}"
	*ngIf="error?.invalid && (error?.dirty || error?.touched)"
	class="text-danger error-wrapper mt-12"
	aria-live="polite"
	aria-atomic="true"
>
	<div
		*ngIf="error.errors.ngbDate?.invalid || error.errors.pattern"
		translate
		[translateParams]="{ value: inputName }"
	>
		Invalid format
	</div>
	<div
		*ngIf="error.errors.ngbDate?.maxDate"
		translate
		[translateParams]="{ value: inputName }"
	>
		Start Date need to be lower than End Date
	</div>
	<div
		*ngIf="error.errors.ngbDate?.minDate"
		translate
		[translateParams]="{ value: inputName }"
	>
		End Date need to be greater than Start Date
	</div>
	<div
		*ngIf="error.errors.max"
		translate
		[translateParams]="{ value: inputName, digits: error.errors.max.max }"
	>
		FIELD_MAX_NUMBER
	</div>
	<div
		*ngIf="error.errors.min"
		translate
		[translateParams]="{ value: inputName, digits: error.errors.min.min }"
	>
		FIELD_MIN_NUMBER
	</div>
	<div
		*ngIf="error.errors.exact_number"
		translate
		[translateParams]="{ value: inputName, digits: error.errors.exact_number }"
	>
		FIELD_DIGITS
	</div>
	<div *ngIf="error.errors.invalidMsg" translate>
		{{error.errors.invalidMsg | translate}}
	</div>
	<div *ngIf="error.errors.custom">
		{{ error.errors.custom }}
	</div>
	<div
		*ngIf="error.errors.required"
		translate
		[translateParams]="{ value: inputName }"
	>
		FIELD_REQUIRED
	</div>
	<div *ngIf="error.errors.email" translate>Invalid Email</div>
	<div *ngIf="error.errors.mustMatch" translate>
		Password confirmation doesn't match
	</div>
	<div
		*ngIf="error.errors.minlength"
		translate
		[translateParams]="{
			value: inputName,
			requiredLength: error.errors.minlength.requiredLength
		}"
	>
		MIN_LENGTH
	</div>
	<div
		*ngIf="error.errors.requiredFileType"
		translate
		[translateParams]="{ value: availableTypes.join(', ') }"
	>
		Available extensions are:
	</div>
	<div
		*ngIf="error.errors.bigger_than_min"
		translate
	>
		End Date need to be greater than Start Date
	</div>
	<div
		*ngIf="error.errors.lower_than_max"
		translate
	>
		Start Date need to be lower than End Date
	</div>
</div>
